import { Typography } from "@mui/material";
import { useState, useEffect, useCallback, useRef } from "react";
import { LandingNavbar } from "../../components/LandingNavbar";
import {
  StyledDensityHardwareContainer,
  StyledLandingPageWrapper,
} from "./landing.styled";
import { useWindowDimensions } from "../../utils/hooks/windowDimensionsHook";
import { Footer } from "../../components/Landing/Footer";
import { useDispatch } from "react-redux";
import Decks4 from "../../assets/decks-4.svg";
import NoWatermark from "../../assets/no-watermark.svg";
import { Header } from "../../components/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { api } from "../../api/base";
import { decodeToken } from "react-jwt";
import { analyticsEvent } from "../../redux/actions/analytics";
import { setLoginModalOpen } from "../../redux/actions/app";
import { StyledAuthButton } from "../../components/micro/micro.styled";
import HardwareDensity from "../../assets/Hardware-with-shadow.png";
import { useUserContext } from "../../context/UserContext";
import { UpgradeModal } from "../Profile/modal/UpgradeModal";
import { SinglePlanItem, SubscriptionClass } from "../../types/subscription";
import { useSnackBar } from "../../context/SnackBar";
import { ConfirmRenewalModal } from "../../components/modals/ConfirmRenewal";

import { isBFSale } from "../../utils/sales";

export const PricingPage = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [visible] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const { showSnackBar } = useSnackBar();
  const { user, setPlan, isUserLoggedIn, fetchingUserData, isPasswordSet } =
    useUserContext();
  const [renewalModalOpen, setRenewalModalOpen] = useState<boolean>(false);

  type upgradeDataType = {
    open: boolean;
    url: string;
    handleCloseModal: () => void;
    price: string;
    additionalText: string;
  };
  const [upgradeData, setUpgradeData] = useState({} as upgradeDataType);
  const [loadingUpgrade, setLoadingUpgrade] = useState(false);
  const [loadingPurchase, setLoadingPurchase] = useState(false);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(true);
  const [plans, setPlans] = useState<SinglePlanItem[]>([]);
  const [subscriptions, setSubscriptions] = useState<SubscriptionClass>(
    {} as SubscriptionClass,
  );

  const upgradeButtonRef = useRef<any>(null);
  const hardwareButtonRef = useRef<any>(null);

  const getPlans = async (): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> =
        await api.get("chargebee/plans");
      setPlans(data);
    } catch (err) {}
  };

  const renewSubscription = async (): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/renew-subscription",
        {
          planId: subscriptions.id,
          trialEnd: subscriptions.trial_end,
          status: subscriptions.status,
        },
      );
      showSnackBar(
        "Successfully reactivated subscription.",
        "success",
        "center",
        2000,
        false,
        "auto",
        "95px",
      );
      await getSubscriptions();
      setRenewalModalOpen(false);
    } catch (err: any) {
      const { response } = err;
      showSnackBar(response.data.error_msg, "error");
    }
  };

  const handleSubscriptionRenewal = async (): Promise<void> => {
    renewSubscription();
  };

  const getSubscriptions = async (): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> = await api.get(
        "chargebee/subscriptions",
      );
      setSubscriptions(data);
      setPlan({ planId: data.id, planName: data.item_price_id });
    } catch (err) {}
  };
  const handleRenewalConfirmation = () => {
    setRenewalModalOpen(true);
  };

  useEffect(() => {
    if (fetchingUserData) {
      setIsLoadingSubscriptions(true);
      return;
    }

    const getPlansAndSubscriptions = async () => {
      try {
        await getPlans();
        await getSubscriptions();
      } finally {
        setIsLoadingSubscriptions(false);
      }
    };

    if (isUserLoggedIn) {
      getPlansAndSubscriptions();
    } else {
      setIsLoadingSubscriptions(false);

      if (searchParams.has("purchase")) {
        dispatch(setLoginModalOpen(true));
      }
    }
  }, [fetchingUserData, isUserLoggedIn]);

  useEffect(() => {
    if (fetchingUserData || !isUserLoggedIn || isLoadingSubscriptions) {
      return;
    }

    switch (searchParams.get("purchase")) {
      case "upgrade":
        upgradeButtonRef.current.click();
        break;
      case "hardware":
        hardwareButtonRef.current.click();
        break;
      default:
        break;
    }
  }, [fetchingUserData, isUserLoggedIn, isLoadingSubscriptions]);

  useEffect(() => {
    dispatch(
      analyticsEvent("EVENT", "Pricing Page Visited", {
        page: "Pricing Page",
      }),
    );
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, [isOpen]);

  const upgradeButtonText = () => {
    if (subscriptions.status === "non_renewing") {
      return "Reactivate";
    } else if (subscriptions.status === "cancelled") {
      return "Renew";
    }
    return "Upgrade";
  };

  const isUpgradeButtonDisabled = () => {
    if (fetchingUserData || isLoadingSubscriptions || loadingUpgrade) {
      return true;
    }

    if (
      Object.keys(subscriptions).length == 0 ||
      subscriptions?.item_price_id === "Free-USD-Monthly"
    ) {
      return false;
    }

    if (
      subscriptions.status === "non_renewing" ||
      subscriptions.status === "cancelled"
    ) {
      return false;
    }

    // TODO: More conditions?
    return true;
  };

  const isHardwareButtonDisabled = () => {
    return fetchingUserData || loadingPurchase;
  };

  const buyHardwarePlan = async (): Promise<void> => {
    if (!isUserLoggedIn && !fetchingUserData) {
      getDensity(false, true);
      return;
    }
    try {
      const token = localStorage.getItem("access_token");
      //@ts-ignore
      const decodedToken = decodeToken(token);
      //@ts-ignore
      const email = decodedToken?.email;
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/checkout-hardware",
        {
          redirect_url: `${process.env.REACT_APP_URL}/account/upgrade-success?plan=hardware`,
          email,
        },
      );
      navigate(`/account/checkout`, {
        state: {
          url: data.hosted_page.url,
          additionalText: "Hardware - $300",
          title: "REC-1",
          cancelUrl: "/pricing",
        },
      });
    } catch (err) {
      console.error(err);
    }
    setLoadingPurchase(false);
  };

  const getDensity = (isUpgrade = false, isHardware = false) => {
    if (props.getDensity) {
      props.getDensity();
    } else {
      localStorage.removeItem("landing");

      const ref = searchParams.get("ref");
      const navParams = `${isUpgrade ? "?upgrade=true" : ""}${isHardware ? "?plan=hardware" : ""}`;

      if (user?.email && !isPasswordSet) {
        navigate(`/onboarding/5/${ref || ""}${navParams}`);
        return;
      }

      if (ref) {
        navigate(`/onboarding/0/${ref}${navParams}`);
      } else {
        navigate(`/onboarding/1${navParams}`);
      }
    }
  };

  const handleDownload = () => {
    dispatch(
      analyticsEvent("EVENT", "Download Density App", {
        "OS Downloaded": "Universal",
        location: "Pricing Page",
      }),
    );

    api
      .post("/auth/download", {
        email: user?.email,
        os: "universal",
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      });
    navigate("/onboarding/6?instructions=true");
  };

  const handleDownloadFree = () => {
    localStorage.setItem("onboarding_in_progress", "true");
    dispatch(
      analyticsEvent("CLICK", "Download Free Button clicked", {
        page: "Pricing Page",
      }),
    );
    if (isUserLoggedIn && !fetchingUserData) {
      handleDownload();
    } else {
      getDensity();
    }
  };

  const handleUpgrade = async () => {
    setLoadingUpgrade(true);
    const upgradeButtonTextValue = upgradeButtonText();
    dispatch(
      analyticsEvent("CLICK", `${upgradeButtonTextValue} button clicked`, {
        location: "Pricing Page",
      }),
    );

    if (isUserLoggedIn && upgradeButtonTextValue === "Upgrade") {
      const buyPlan = async (): Promise<void> => {
        try {
          const { data }: AxiosResponse<any, any> = await api.post(
            "chargebee/checkout",
            {
              item_price_id: "Premium-USD-Monthly",
              // unit_price: plan.price,
              redirect_url: `${process.env.REACT_APP_URL}/account${searchParams.get("fromDesktop") ? "?fromDesktop=true" : ""}`,
              coupon: isBFSale() ? "BF2024" : "",
            },
          );
          const handleCloseModal = () => {
            setUpgradeData({ ...upgradeData, open: false });
            setLoadingUpgrade(false);
            navigate("/onboarding/6");
          };
          setUpgradeData({
            ...upgradeData,
            url: data.url,
            price: isBFSale() ? "BF2024" : "$15/month",
            open: true,
            handleCloseModal,
          });
          setLoadingUpgrade(false);
        } catch (err) {}
      };
      try {
        await buyPlan();
      } catch (err) {
        console.error(err);
        setLoadingUpgrade(false);
      }
      return;
    } else {
      if (
        isUserLoggedIn &&
        (upgradeButtonTextValue === "Reactivate" ||
          upgradeButtonTextValue === "Renew")
      ) {
        handleRenewalConfirmation();
      } else {
        getDensity(true);
      }
    }
    setLoadingUpgrade(false);
  };

  const handlePurchase = () => {
    setLoadingPurchase(true);
    dispatch(
      analyticsEvent("CLICK", "Purchase button clicked", {
        page: "Pricing Page",
      }),
    );
    buyHardwarePlan();
  };

  return (
    <>
      <StyledLandingPageWrapper className="landing-page-wrapper">
        {isUserLoggedIn ? (
          <Header />
        ) : (
          <LandingNavbar
            visible={visible}
            ww={width}
            getDensity={() => {
              return handlePurchase();
            }}
          />
        )}
        <main style={{ maxWidth: 1360, margin: "auto" }}>
          <StyledDensityHardwareContainer>
            <div className="mb-[72px] mt-[72px]">
              <Typography
                className="density-hero-heading hardware-density-heading"
                variant="h1"
                component="h1"
                sx={{
                  background:
                    "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                  "-webkit-background-clip": {
                    md: "text",
                    xs: "text",
                  },
                  "-webkit-text-fill-color": {
                    md: "transparent",
                    xs: "transparent",
                  },
                }}
              >
                Pricing
              </Typography>
            </div>
          </StyledDensityHardwareContainer>

          <section className="">
            <div className="flex flex-wrap gap-6 ">
              <div className="relative box-border flex w-[100%] flex-col justify-between rounded-[30px] bg-[#FFFFFF0D] p-[48px] pb-[60px] text-center md:w-[calc(50%-24px)]">
                <div className="flex w-[100%]  flex-row justify-between pb-[24px]">
                  <div className="flex flex-col">
                    <h2 className="whitespace-nowrap pb-[8px] text-left font-['PPRightGrotesk-WideMedium'] text-[34px]  font-black leading-[27.42px] !text-primary-purple">
                      {"Basic"}
                    </h2>
                    <p className="font-sm text-left font-inter text-base leading-[24px] tracking-wide !text-primary-purple">
                      Free
                    </p>
                  </div>
                  <div>
                    <img src={Decks4} alt="Decks4" className="w-[108px]" />
                  </div>
                </div>

                <div className="pb-6 text-left font-inter text-sm font-medium leading-6 tracking-[0.0125rem] !text-base-gray">
                  <li className="disc-list">
                    Complete use of the{" "}
                    <span
                      className="cursor-pointer text-left text-center tracking-wide underline"
                      style={{ color: "#575F9E" }}
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Density DAW
                    </span>{" "}
                    for free
                  </li>
                  <div className="pl-6">
                    <p>– Point & Click to create a set</p>
                    <p>
                      {" "}
                      – Capture a live set by connecting your decks to your
                      laptop
                    </p>
                  </div>
                  <li className="pb-2">Master any Density recording</li>
                  <p>Limitation:</p>
                  <li className="disc-list">
                    On export there is an unobtrusive audio watermark that says,
                    “mixed with Density”
                  </li>
                </div>
                <div className="w-[100%]">
                  <a
                    href={`${isUserLoggedIn && !fetchingUserData ? "https://release.density.one/Density-Installer-Universal-Latest.dmg" : ""}`}
                    download={false}
                  >
                    <StyledAuthButton
                      onClick={handleDownloadFree}
                      variant={"outlined"}
                      className="min-w-[100%]"
                      disabled={fetchingUserData}
                      sx={{
                        m: 0,
                        minWidth: "100%",
                        background: "rgba(255, 255, 255, 0.08)",
                        borderColor: "rgba(252, 252, 252, 0.5)",
                        letterSpacing: "0.5px",
                        fontSize: "11px",
                        color: "rgba(252, 252, 252, 0.6)",
                        fontWeight: 600,
                      }}
                    >
                      Download Free
                    </StyledAuthButton>
                  </a>
                </div>
              </div>

              <div className="relative box-border flex w-[100%] flex-col justify-between rounded-[30px] bg-[#FFFFFF0D] p-[48px] pb-[60px] text-center md:w-[calc(50%-0.5rem)]">
                <div className="flex w-[100%]  flex-row justify-between pb-[24px]">
                  <div className="flex flex-col">
                    <h2 className="whitespace-nowrap pb-[8px] text-left font-['PPRightGrotesk-WideMedium'] text-[34px]  font-black leading-[27.42px] !text-primary-purple">
                      {"Premium"}
                    </h2>
                    <p className="font-sm text-left font-inter text-base leading-[24px] tracking-wide !text-primary-purple">
                      <span className={isBFSale() ? "line-through" : ""}>
                        $15/mth
                      </span>
                      <br />
                      {isBFSale() && (
                        <span className="!text-alert-orange-800">
                          Holiday Sale - 3 Months for $1.50
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="pb-6 text-left font-inter text-sm font-medium leading-6 tracking-[0.0125rem] !text-base-gray">
                  <p className="pb-[50px]">Removes the audio watermark</p>
                  <div className="-mx-[48px] w-[calc(100%+96px)]">
                    <img src={NoWatermark} alt="no-watermark" className="" />
                  </div>
                </div>
                <div className="w-[100%]">
                  <StyledAuthButton
                    ref={upgradeButtonRef}
                    onClick={handleUpgrade}
                    variant={"contained"}
                    color="primary"
                    className="min-w-[100%]"
                    disabled={isUpgradeButtonDisabled()}
                    sx={{
                      minWidth: "100%",
                      height: {
                        md: 44,
                        xs: 36,
                      },
                      margin: 0,
                      padding: {
                        xs: "10px 16px",
                        md: "15px",
                      },
                      backgroundColor: "#c1c5dd",
                      mb: "0px",
                      fontSize: {
                        md: "13px",
                      },
                    }}
                  >
                    {upgradeButtonText()}
                  </StyledAuthButton>
                </div>
              </div>

              <div className="relative box-border flex w-[100%] flex-col-reverse justify-between rounded-[30px] bg-[#FFFFFF0D] p-[48px] pb-[60px] text-center md:w-[100%] lg:flex-row">
                <div className="flex w-[100%] flex-col justify-between lg:w-[50%] lg:pb-[24px]">
                  <div className="flex flex-col pb-[24px] ">
                    <h2 className="pb-[8px] text-center font-['PPRightGrotesk-WideMedium'] text-[34px] font-black leading-[29.62px] !text-primary-purple lg:text-left">
                      {"Club/Festival Hardware"}
                    </h2>
                    <p className="font-sm text-center font-inter text-base leading-[24px] tracking-wide !text-primary-purple lg:text-left">
                      $300 - one time
                    </p>
                  </div>

                  <div className="h-[100%] pb-6 text-center font-inter text-sm font-medium leading-6 tracking-[0.0125rem] tracking-[0.0125rem] !text-base-gray lg:text-left">
                    <div className="pb-4 lg:max-w-[55ch]">
                      The hardware is designed for clubs / festivals. The free
                      version of the software allows you to manage your
                      recordings and automatically splits sets. You can then
                      send the set to the DJ for mastering.
                    </div>

                    <p>If you choose to buy the hardware for personal use:</p>
                    <div className="pl-4">
                      <li className="disc-list">
                        You can use the free version of the Density DAW to edit
                        your sets.
                      </li>
                      <li className="disc-list">
                        Upgrade to remove the audio watermark.
                      </li>
                    </div>
                  </div>
                  <div className="flex w-[100%] justify-center lg:justify-start">
                    <StyledAuthButton
                      ref={hardwareButtonRef}
                      onClick={handlePurchase}
                      variant={"contained"}
                      color="primary"
                      className="min-w-[100%]"
                      disabled={isHardwareButtonDisabled()}
                      sx={{
                        // minWidth: "100%",
                        height: {
                          md: 44,
                          xs: 36,
                        },
                        margin: 0,
                        padding: {
                          xs: "10px 16px",
                          md: "15px",
                        },
                        backgroundColor: "#c1c5dd",
                        mb: "0px",
                        fontSize: {
                          md: "13px",
                        },
                      }}
                    >
                      purchase
                    </StyledAuthButton>
                  </div>
                </div>
                <div className=" flex w-[100%] justify-center pb-[24px]  lg:w-[50%] lg:pb-0">
                  <img
                    className="hardware-density-image w-[380px] -translate-x-[20px] md:-translate-x-[40px] lg:min-w-[420px] lg:-translate-x-[24px]"
                    src={HardwareDensity}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </main>
        {upgradeData.open && upgradeData.url && (
          <UpgradeModal
            error={""}
            open={upgradeData.open}
            url={upgradeData.url}
            handleClose={() => setUpgradeData({ ...upgradeData, open: false })}
            price={upgradeData.price}
            additionalText={upgradeData.additionalText}
          />
        )}

        <ConfirmRenewalModal
          open={renewalModalOpen}
          handleClose={() => setRenewalModalOpen(false)}
          handleRenewal={handleSubscriptionRenewal}
          subscriptionPrice={`$${subscriptions?.unit_price / 100}`}
          action={
            typeof subscriptions?.next_billing_at === "undefined"
              ? subscriptions?.status === "non_renewing"
                ? `Reactivate`
                : `Renew`
              : "Cancel"
          }
        />
      </StyledLandingPageWrapper>
    </>
  );
};
