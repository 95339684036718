import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { api } from "../../api/base";

import {
  useSearchParams,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { StyledAuthContainer } from "../../components/micro/micro.styled";
import { AuthHeader } from "../../components/AuthHeader";
import { EmailInvite } from "../../components/Auth/EmailInvite";

import { Referrer, RegisterProps } from "../../types/auth";
import { CreatePassword } from "../../components/Auth/CreatePassword";
import { FinalStep } from "../../components/Auth/FinalStep";
import { useSnackBar } from "../../context/SnackBar";
import { FirstStep } from "../../components/Auth/FirstStep";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import DensityLogo from "../../assets/Density-logo.svg";
import { useDispatch } from "react-redux";
import { analyticsEvent } from "../../redux/actions/analytics";
import { Landing } from "../Landing";
import { useWindowDimensions } from "../../utils/hooks/windowDimensionsHook";
import { useCookies } from "react-cookie";
import { decodeToken } from "react-jwt";
import { AxiosResponse } from "axios";
import { UpgradeModal } from "../Profile/modal/UpgradeModal";
import { useUserContext } from "../../context/UserContext";
import { GTMEvent, gtmTriggerEvent } from "../../utils/gtm";
import { loadLocalUtmParams } from "../../storage/utm.params";
import { getUserPlatform } from "../../utils/user.info";
import { loadLocalUserSettings } from "../../storage/user.settings";
import { loadLocalReferralInfo } from "../../storage/referral.info";

import { isBFSale } from "../../utils/sales";

export const RegisterPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  let { refId, stepNum } = useParams();
  const navigate = useNavigate();
  const step = parseInt(stepNum || "0");
  const { showSnackBar } = useSnackBar();
  const location = useLocation();
  const [userExists, setUserExists] = useState(false);
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isProInvite, setIsProInvite] = useState(false);
  const [reffererId, setReffererId] = useState<any>(null);
  const [addedToWaitlist, setAddedToWaitlist] = useState(false);
  const [onboardingStarted, setOnboardingStarted] = useState(false);
  const [cookies, setCookie] = useCookies();
  const [userData, setUserData] = useState<RegisterProps>({
    id: localStorage.getItem("user_id") || uuid(),
    email:
      searchParams.get("email")?.replaceAll(" ", "+") ||
      localStorage.getItem("user_email") ||
      "",
    password: "",
    name: "",
    soundcloudURL: localStorage.getItem("instagramURL") || "instagram.com/",
    proInvite: false,
  });
  const { setIsPasswordSet } = useUserContext();
  type upgradeDataType = {
    open: boolean;
    url: string;
    handleCloseModal: () => void;
    price: string;
    additionalText: string;
  };
  const [upgradeData, setUpgradeData] = useState({} as upgradeDataType);
  const [soundcloudHasInput, setSoundcloudHasInput] = useState(
    Boolean(localStorage.getItem("instagramURL")),
  );

  const [referrerData, setReferrerData] = useState<Referrer | undefined>();
  const [invitesLeft, setInvitesLeft] = useState<number>(
    parseInt(localStorage.getItem("invites") || ""),
  );

  const setStep = (newStep: string, includeInstructions: boolean = false) => {
    console.log(newStep, stepNum, includeInstructions);
    if (
      (newStep === "5" &&
        stepNum === "6") /* && searchParams.get('upgrade') */ ||
      (newStep === "5" &&
        stepNum === "1" &&
        localStorage.getItem("password_added"))
    ) {
      return showSnackBar(
        "Your password has been set. Please continue to the next step.",
        "info",
        "center",
        2000,
      );
    }

    // TODO: Surely this conflicts with setReffererId below?
    const refId = localStorage.getItem("refId");
    let refPathPart = "";
    if (refId) {
      setReffererId(refId);
      refPathPart = `/${refId}`;
    }

    if (searchParams.get("state") !== "succeeded" && newStep === "6") {
      searchParams.delete("plan");
    }

    if (includeInstructions) {
      searchParams.set("instructions", "true");
    }

    const searchParamsStr = searchParams.toString();
    const searchParamsPathPart = searchParamsStr ? `?${searchParamsStr}` : "";

    navigate(`/onboarding/${newStep}${refPathPart}${searchParamsPathPart}`);
  };

  const getDensity = () => {
    dispatch(
      analyticsEvent("CLICK", "Get Density Clicked", {
        page: "Landing Page",
        flow: "invited",
        ...referrerData,
        refId: localStorage.getItem("refId") ?? "",
        proInvite: localStorage.getItem("proInvite") ?? "",
      }),
    );
    dispatch({ type: "GET_DENSITY_CLICKED", payload: { fbp: cookies._fbp } });
    setOnboardingStarted(true);
  };

  useEffect(() => {
    const email = searchParams.get("email")?.replaceAll(" ", "+");
    if (email) {
      localStorage.setItem("onboarding_in_progress", "true");
      localStorage.setItem("user_email", email || "");
      setUserData({ ...userData, email: email || "" });
    }

    const access_token = searchParams.get("access_token");
    const refresh_token = searchParams.get("refresh_token");

    if (access_token && refresh_token) {
      localStorage.setItem("access_token", access_token || "");
      localStorage.setItem("refresh_token", refresh_token || "");
      localStorage.setItem("password_added", "true");
    }

    const user_id = searchParams.get("user_id");

    if (user_id) {
      localStorage.setItem("user_id", user_id || "");
      setUserData({ ...userData, id: user_id || "" });
    }
    const proInvite = Boolean(
      searchParams.get("proInvite") ||
        localStorage.getItem("proInvite") ||
        false,
    );
    setIsProInvite(proInvite);
    const rId = refId || localStorage.getItem("refId") || "";
    setReffererId(rId);
    const madePayment = Boolean(localStorage.getItem("madePayment") || false);
    if (proInvite && (step === 4 || step === 3)) {
      navigate(`/onboarding/5${window.location.search}`);
    }
    if (madePayment && (step === 4 || step === 3)) {
      navigate(`/onboarding/5${window.location.search}`);
    }
  }, [step]);

  const [verifying, setVerifying] = useState(false);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === "instagramLink") {
      if (e.target.value === "") {
        setUserData({
          ...userData,
          soundcloudURL: "instagram.com/",
        });
        return;
      }
      if (e.target.value.startsWith("instagram.com/")) {
        e.target.value = e.target.value.replace("https://instagram.com/", "");
        e.target.value = e.target.value.replace(
          "https://www.instagram.com/",
          "",
        );
        if (e.target.value !== "instagram.com/") {
          e.target.value = e.target.value.replace(/\/$/, "");
        }
        setUserData({
          ...userData,
          soundcloudURL: e.target.value,
        });
        setSoundcloudHasInput(e.target.value.length > "instagram.com/".length);
      }
    } else {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value,
      });
    }
    setFormError("");
  };

  const clearData = (): void => {
    setUserData({
      id: "",
      email: "",
      password: "",
      name: "",
      soundcloudURL: "instagram.com/",
      proInvite: false,
    });
  };

  const onNextKeyPress = (e: any): void => {
    if (e.key === "Enter") {
      if (e.target?.name === "email") {
        checkUserEmail();
      }
      if (e.target?.name === "instagramLink") {
        addUserSoundcloud();
      }
      if (e.target?.name === "password") {
        setUserPassword();
      }
    }
  };

  const getStarted = (): void => {
    setStep("1");
    dispatch(
      analyticsEvent("CLICK", "Accepted Invite", {
        page: "1st Page",
        refId: reffererId ?? "",
        proInvite: referrerData?.proInvite,
      }),
    );
  };

  const getActualRefId = (): string => {
    if (reffererId) {
      return reffererId;
    }

    const referralInfoOpt = loadLocalReferralInfo();
    return referralInfoOpt?.ref_id || "";
  };

  const checkUserEmail = async (): Promise<void> => {
    setLoading(true);
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
      userData.email.toLowerCase(),
    );

    if (!isEmail) {
      setFormError("Please use a valid email address");
      setUserExists(false);
      setLoading(false);
      return;
    }

    const actualRefId = getActualRefId();

    try {
      let link = reffererId
        ? `${window.location.origin}/onboarding/6/${reffererId}`
        : `${window.location.origin}/onboarding/6`;
      link = `${link}?email=${userData.email.toLowerCase()}&user_id=${userData.id}&instructions=true&from=email`;
      link = `${link}${window.location.search.replace("?", "&")}`;

      const deviceType = getUserPlatform();
      const utmParams = loadLocalUtmParams() || {};
      const metaData = loadLocalUserSettings() || {};
      metaData.refId = actualRefId;

      const body = {
        email: userData.email.toLowerCase(),
        proInvite: referrerData?.proInvite,
        refId: actualRefId,
        referrer: referrerData?.referrer || "",
        link,
        invites:
          localStorage.getItem("invites") ||
          referrerData?.invitesLeft ||
          Math.floor(Math.random() * 320) + 50,
        ...utmParams,
        utmParams: utmParams,
        id: localStorage.getItem("user_id") || userData.id,
        metadata: JSON.stringify(metaData),
        deviceType,
        fbclid: localStorage.getItem("fbclid") || "",
      };

      if (
        !localStorage.getItem("user_email") ||
        !localStorage.getItem("password_added")
      ) {
        const check = await api.post("auth/register-check-email", body);
        if (check.data.access_token && check.data.refresh_token) {
          // TODO: Fix ability to create account without password, perhaps by deferring this?
          localStorage.setItem("access_token", check.data.access_token);
          localStorage.setItem("refresh_token", check.data.refresh_token);
        }
        showSnackBar("Successfully sent invite!", "success", "center", 2000);
        dispatch(
          analyticsEvent("INPUT", "IG-2: Email Submitted", {
            page: "Email Page",
            refId: actualRefId,
            proInvite: referrerData?.proInvite,
            email: userData.email.toLowerCase(),
          }),
        );

        localStorage.setItem("user_email", userData.email.toLowerCase());
        setStep("5");
        setFormError("");
        setLoading(false);
        return;
      } else {
        try {
          const response = await api.post("auth/set-user-email", {
            userId: localStorage.getItem("user_id"),
            email: userData.email.toLowerCase(),
            from: "onboarding",
          });

          showSnackBar(
            "Email updated successfully!",
            "success",
            "center",
            2000,
            false,
            "auto",
            "95px",
          );

          searchParams.set("email", userData.email.toLowerCase());
          localStorage.setItem("user_email", userData.email.toLowerCase());
          localStorage.setItem("access_token", response.data.user.access_token);
          localStorage.setItem(
            "refresh_token",
            response.data.user.refresh_token,
          );
          setStep("6");
          setFormError("");
        } catch (err: any) {
          const errorMessage = err?.response?.data?.msg;

          const isAlreadyInUse = errorMessage === "Email is already in use";
          if (isAlreadyInUse) {
            // TODO: The backend should not return an error in this case.
            const token = localStorage.getItem("access_token") || "";
            const decodedToken: any = decodeToken(token);
            const decodedEmail = decodedToken?.email;

            const isActuallyTryingToChange =
              decodedEmail.toLowerCase() !== userData.email.toLowerCase();

            if (isActuallyTryingToChange) {
              showSnackBar(
                "Email is already in use, please use another.",
                "error",
                "center",
                2000,
                false,
                "auto",
                "95px",
              );
              setLoading(false);
              return;
            }
          }

          localStorage.setItem("userExists", "true");

          setStep("6", true);
          setUserExists(true);
        }

        setLoading(false);
      }
    } catch (err: any) {
      const isConflict = err?.response?.status === 409;

      if (isConflict) {
        console.log("User already exists");
        localStorage.setItem("user_email", userData.email.toLowerCase());
        localStorage.setItem("userExists", "true");
        setUserExists(true);
      }

      const isHardware = new URLSearchParams(window.location.search).get(
        "plan",
      );
      const isUpgrade = new URLSearchParams(window.location.search).get(
        "upgrade",
      );
      if (isHardware) {
        navigate(`/hardware?purchase=hardware`);
        return;
      }
      if (isUpgrade) {
        navigate(`/pricing?purchase=upgrade`);
        return;
      }

      const isMobileOrTablet =
        /iOS/i.test(
          JSON.parse(localStorage.getItem("user_settings") || "{}")?.os_name,
        ) || window.innerWidth < 1024;
      setStep("6", !isMobileOrTablet);

      dispatch({
        type: "USER_EXISTS",
        payload: {
          email: userData.email.toLowerCase(),
        },
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    setFormError("");
  }, [location]);

  useEffect(() => {
    const params = {
      imgUrl: searchParams.get("imgUrl") || DensityLogo,
      invites: searchParams.get("invites") || "1000",
      invitesUsed:
        searchParams.get("invitesLeft") || Math.floor(Math.random() * 320) + 50,
      referrer: searchParams.get("referrer") || "",
      proInvite: Boolean(searchParams.get("proInvite") || false),
    };

    if (!searchParams.get("landing")) {
      if (!localStorage.getItem("landing")) {
        setOnboardingStarted(true);
      }
    } else {
      localStorage.setItem("landing", "true");
    }

    const { imgUrl, invites, invitesUsed, referrer, proInvite } = params;
    const iLeft = Number(localStorage.getItem("invites"));
    if (!iLeft) {
      localStorage.setItem("invites", invitesUsed.toString());
    }
    if (proInvite) {
      localStorage.setItem("proInvite", "true");
    }
    if (refId) {
      localStorage.setItem("refId", refId);
    }
    setReferrerData({
      imgUrl,
      invites: Number(invites),
      referrer,
      invitesLeft: iLeft ? iLeft : Number(invitesUsed),
      proInvite: proInvite,
    });
    localStorage.setItem("imgUrl", imgUrl);
    localStorage.setItem("referrer", referrer);
  }, []);

  useEffect(() => {
    let timeoutId: any = null;

    function reduceNumber() {
      let iLeft = referrerData?.invitesLeft || invitesLeft;
      if (iLeft > 83) {
        const delay = Math.floor(Math.random() * 15000) + 1000;
        const newInvitesLeft = invitesLeft - 1;
        timeoutId = setTimeout(() => {
          setInvitesLeft((prevValue) => prevValue - 1);
          setReferrerData((prevValue) => {
            if (prevValue) {
              return {
                ...prevValue,
                invitesLeft: prevValue.invitesLeft - 1,
              };
            }
          });
          localStorage.setItem("invites", newInvitesLeft.toString());
        }, delay);
      } else {
        if (invitesLeft < 83) {
          setInvitesLeft(83);
          setReferrerData((prevValue) => {
            if (prevValue) {
              return {
                ...prevValue,
                invitesLeft: 83,
              };
            }
          });
          localStorage.setItem("invites", "83");
        }
      }
    }

    reduceNumber();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [invitesLeft]);

  const validateSoundCloudURL = (url: string) => {
    const re = /^instagram.com\/[a-zA-Z0-9_.]+$/;
    return re.test(url.toLowerCase());
  };

  const addUserSoundcloud = async (): Promise<void> => {
    if (
      !userData.soundcloudURL ||
      !validateSoundCloudURL(userData.soundcloudURL)
    ) {
      setFormError(`That's an invalid link.`);
      return;
    }

    try {
      setVerifying(true);
      const { data } = await api.post("auth/add-soundcloud-url", {
        email: userData.email.toLowerCase(),
        soundcloudURL: userData.soundcloudURL,
        refId: reffererId || "",
        proInvite: referrerData?.proInvite,
        sessionId: localStorage.getItem("sessionId"),
        utmParams: JSON.parse(localStorage.getItem("utm_params") || "{}"),
      });
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem("instagramURL", userData.soundcloudURL);
      dispatch(
        analyticsEvent("INPUT", "IG-3: Instagram Submitted", {
          page: "Email Page",
          refId: reffererId ?? "",
          proInvite: referrerData?.proInvite,
          instagramLink: userData.soundcloudURL,
        }),
      );
      setStep("5");
    } catch (err: any) {
      const { response } = await err;
      setFormError(response.data.message);
    } finally {
      setVerifying(false);
    }
  };

  const setUserPassword = async (): Promise<void> => {
    const isHardware = new URLSearchParams(window.location.search).get("plan");
    const isUpgrade = new URLSearchParams(window.location.search).get(
      "upgrade",
    );
    const userEmail = localStorage.getItem("user_email");

    const actualRefId = getActualRefId();

    try {
      const { data } = await api.post("auth/set-user-password", {
        email: userEmail,
        password: userData.password,
        link: refId
          ? `${window.location.origin}/onboarding/5/${refId}?email=${userEmail}`
          : `${window.location.origin}/onboarding/5?email=${userEmail}`,
      });
      localStorage.setItem("password_added", "true");
      setIsPasswordSet(true);

      dispatch(
        analyticsEvent("INPUT", "IG-4: Password Submitted", {
          page: "Password Page",
          refId: actualRefId,
          proInvite: referrerData?.proInvite,
          email: userEmail,
        }),
      );
      dispatch(
        analyticsEvent("EVENT", "Registered", {
          plan: "free",
        }),
      );
      gtmTriggerEvent(GTMEvent.Registered);

      if (isHardware) {
        try {
          const { data }: AxiosResponse<any, any> = await api.post(
            "chargebee/checkout-hardware",
            {
              redirect_url: `${process.env.REACT_APP_URL}/onboarding/6/?plan=hardware`,
              email: userData.email,
            },
          );

          navigate(`/checkout`, {
            state: {
              url: data.hosted_page.url,
              additionalText: "Hardware - $300",
              title: "REC-1",
              cancelUrl: `/onboarding/6/`,
            },
          });
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
        return;
      }
      if (isUpgrade) {
        const buyPlan = async (): Promise<void> => {
          try {
            const { data }: AxiosResponse<any, any> = await api.post(
              "chargebee/checkout",
              {
                item_price_id: "Premium-USD-Monthly",
                // unit_price: plan.price,
                redirect_url: `${process.env.REACT_APP_URL}/account${searchParams.get("fromDesktop") ? "?fromDesktop=true" : ""}`,
                coupon: isBFSale() ? "BF2024" : "",
              },
            );
            const handleCloseModal = () => {
              setUpgradeData({ ...upgradeData, open: false });
              navigate("/onboarding/6");
            };
            setUpgradeData({
              ...upgradeData,
              url: data.url,
              price: isBFSale() ? "BF2024" : "$15/month",
              open: true,
              handleCloseModal,
            });
          } catch (err) {}
        };
        try {
          await buyPlan();
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
        return;
      }
      setStep("6");
    } catch (err: any) {
      const { response } = await err;
      showSnackBar(response.data, "error");
    }
  };

  useEffect(() => {
    const getPlanResponse = searchParams.get("state");
    if (getPlanResponse === "succeeded") {
      if (
        !searchParams.get("plan") ||
        searchParams.get("plan") !== "hardware"
      ) {
        setStep("5");
      }
    }
  }, [searchParams]);

  const handleBackButton = (): void => {
    if (step === 6) {
      console.log("addedToWaitlist", addedToWaitlist);
      if (addedToWaitlist) {
        setAddedToWaitlist(false);
      } else {
        setStep("5");
      }
    }
  };

  const handleSetStep = (stepNumber: string): void => {
    if (step === 1) {
      if (!localStorage.getItem("user_email")) {
        checkUserEmail();
        setLoading(false);
        return;
      }
    }
    if (step === 2) {
      if (!localStorage.getItem("access_token") && stepNumber !== "1") {
        addUserSoundcloud();
        return;
      }
    }
    if (stepNumber === "6" && !localStorage.getItem("password_added")) {
      showSnackBar("Please complete previous steps.", "error", "center", 2000);
      return;
    }
    setLoading(false);
    setStep(stepNumber);
  };

  const handleBackToEmail = () => {
    setStep("1");
  };

  const handleContinue = () => {
    localStorage.removeItem("onboarding_in_progress");
    localStorage.removeItem("user_email");
    localStorage.removeItem("invites");
    localStorage.removeItem("instagramURL");
    localStorage.removeItem("password_added");
    localStorage.removeItem("referrer");
    localStorage.removeItem("imgUrl");
    clearData();
    if (localStorage.getItem("userExists")) {
      localStorage.removeItem("userExists");
      showSnackBar(
        "You already have an account. Please login to continue.",
        "info",
        "initial",
        3000,
        false,
        "400px",
      );
      navigate("/login");
      return;
    }
  };

  if (stepNum === "0" && !onboardingStarted) {
    return <Landing isOnboarding={true} getDensity={getDensity} />;
  }

  return (
    <StyledAuthContainer>
      <AuthHeader
        step={stepNum}
        showProgress
        proInvite={!!refId}
        showBackButton={addedToWaitlist}
        backButtonHandler={() => {
          setAddedToWaitlist(false);
        }}
        setStep={handleSetStep}
        width={width}
      />
      {stepNum === "0" && (
        <FirstStep
          referrer={referrerData}
          userData={userData}
          nextUserFunction={getStarted}
          onChangeHandler={onChangeHandler}
          onNextKeyPress={onNextKeyPress}
        />
      )}
      {stepNum === "1" && (
        <EmailInvite
          userData={userData}
          nextUserFunction={checkUserEmail}
          onChangeHandler={onChangeHandler}
          userExists={userExists}
          formError={formError}
          onNextKeyPress={onNextKeyPress}
          loading={loading}
        />
      )}
      {/* {stepNum === "2" && (
        <InstagramStep
          userData={userData}
          nextUserFunction={addUserSoundcloud}
          onChangeHandler={onChangeHandler}
          formError={formError}
          instagramHasInput={soundcloudHasInput}
          onNextKeyPress={onNextKeyPress}
        />
      )} */}
      {/* {stepNum === "3" && <Tutorial setStep={setStep} refId={reffererId} />}
      {stepNum === "4" && (
        <Payment
          nextUserFunction={() => {}}
          onChangeHandler={() => {}}
          userData={userData}
          referrer={referrerData}
          onNextKeyPress={onNextKeyPress}
          setStep={handleSetStep}
        />
      )} */}
      {stepNum === "5" && (
        <CreatePassword
          userData={userData}
          nextUserFunction={setUserPassword}
          onChangeHandler={onChangeHandler}
          onNextKeyPress={onNextKeyPress}
        />
      )}
      {stepNum === "6" && (
        <FinalStep
          clearData={clearData}
          handleContinue={handleContinue}
          backToEmail={handleBackToEmail}
          setAddedToWaitlist={setAddedToWaitlist}
          addedToWaitlist={addedToWaitlist}
        />
      )}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(2px)",
        }}
        open={verifying}
      >
        <Box
          width={"100px"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
        >
          <CircularProgress
            sx={{
              marginBottom: "20px",
            }}
            color="inherit"
          />
          Verifying ...
        </Box>
      </Backdrop>
      {upgradeData.open && upgradeData.url && (
        <UpgradeModal
          error={""}
          open={upgradeData.open}
          url={upgradeData.url}
          handleClose={upgradeData.handleCloseModal}
          price={upgradeData.price}
          additionalText={upgradeData.additionalText}
        />
      )}
    </StyledAuthContainer>
  );
};
