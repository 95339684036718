import { loadLocalJSON, storeLocalJSON } from "./local.storage";
import { markSessionFlagIfAbsent, removeSessionFlag } from "./session.flags";

const tryQueryUserSettings = async (): Promise<object | null> => {
  if (!markSessionFlagIfAbsent("querying_user_settings")) {
    return null;
  }

  try {
    return await tryQueryUserSettingsInternal();
  } finally {
    removeSessionFlag("querying_user_settings");
  }
};

const tryQueryUserSettingsInternal = async (): Promise<object | null> => {
  try {
    const rawPayload = await fetch(
      "https://api.ipregistry.co/?key=ira_SlYy48WmR17vLu9J7P9tovmh8iKgp52R7ulZ",
    );
    const payload = await rawPayload.json();

    return {
      location_lat: payload?.location?.latitude,
      location_long: payload?.location?.longitude,
      platform: `${payload?.user_agent?.device?.type} web: ${payload?.user_agent?.device?.brand} (${payload?.user_agent?.device?.name})`,
      device_family: `${payload?.user_agent?.device?.brand}`,
      country: payload?.location?.country?.name,
      city: payload?.location?.city,
      region: payload?.location?.region?.name,
      carrier: payload?.connection?.organization,
      os_name: `${payload?.user_agent?.os?.name}`,
      os_version: payload?.user_agent?.os?.version,
      language: `${payload?.location?.language?.code}`,
      ip: payload?.ip,
    };
  } catch (error) {
    console.warn("Failed to query user settings:", error);
    return null;
  }
};

export const fetchAndRememberUserSettings = async () => {
  if (loadLocalJSON("user_settings")) {
    return;
  }

  const userSettingsOpt = await tryQueryUserSettings();
  if (userSettingsOpt) {
    storeLocalUserSettings(userSettingsOpt);
  }
};

export const storeLocalUserSettings = (userSettings: any) => {
  storeLocalJSON("user_settings", userSettings);
};

export const loadLocalUserSettings = (): any | null => {
  return loadLocalJSON("user_settings");
};
