import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import { motion, AnimatePresence } from "framer-motion";

import "./style.css";

import LandingLogo from "../../assets/Density Logo cropped.svg";
import { StyledAuthButton } from "../micro/micro.styled";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { NavbarItems } from "./NavbarItems";
import { LoginModal } from "../modals/Login";
import { setLoginModalOpen } from "../../redux/actions/app";
import { useDispatch } from "react-redux";
import { ForgotPasswordModal } from "../modals/ForgotPassword";
import { useMediaQuery } from "@mui/material";

export interface LandingNavbarProps {
  visible: boolean;
  ww: number;
  isOnboarding?: boolean | undefined;
  getDensity?: () => void;
}

export const LandingNavbar = ({
  visible,
  ww,
  isOnboarding,
  getDensity,
}: LandingNavbarProps) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isToggled, setIsToggled] = React.useState(false);
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md"),
  );
  const location = useLocation();
  const isHardwarePage =
    location.pathname === "/hardware" || location.pathname === "/rec-setup";
  React.useEffect(() => {
    const deviceId = localStorage.getItem("device_id");
    async function generateDeviceId() {
      try {
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        const { width, height, colorDepth } = window.screen;
        const screenResolution = `${width}x${height}`;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const language = navigator.language;
        const hardwareConcurrency = navigator.hardwareConcurrency;
        const maxTouchPoints = navigator.maxTouchPoints;

        const dataToHash =
          userAgent +
          platform +
          screenResolution +
          timezone +
          language +
          colorDepth +
          hardwareConcurrency +
          maxTouchPoints;
        const hashBuffer = await crypto.subtle.digest(
          "SHA-256",
          new TextEncoder().encode(dataToHash),
        );
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((byte) => byte.toString(16).padStart(2, "0"))
          .join("");
        localStorage.setItem("device_id", hashHex);

        return hashHex;
      } catch (error) {
        console.error("Error generating device ID:", error);
        throw error;
      }
    }

    if (!deviceId) {
      generateDeviceId();
    }
  }, [localStorage.getItem("device_id")]);
  const pages = [
    {
      name: "Login",
      route: "/login",
      handleOnClick: () => dispatch(setLoginModalOpen(true)),
    },
  ];

  React.useEffect(() => {
    if (!isToggled && searchParams.get("plan") === "hardware") {
      setIsToggled(true);
      dispatch(setLoginModalOpen(true));
    }
  }, []);

  const navContainer = {
    visible: {
      opacity: 1,
      transition: {
        x: { velocity: 100 },
        duration: 0.3,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        x: { velocity: 100 },
        duration: 0.3,
      },
    },
  };

  React.useEffect(() => {
    if (ww > 769) {
      closeNavbar();
    }
  }, [ww]);

  React.useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        closeNavbar();
      }
    });
    return () => {
      window.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          closeNavbar();
        }
      });
    };
  }, []);

  const closeNavbar = () => {
    setIsToggled(false);
    document.body.style.overflow = "unset";
  };

  const toggleNavbar = () => {
    setIsToggled(!isToggled);
    if (isToggled) {
      document.body.style.overflow = "unset";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const getButtonText = () => {
    if (isHardwarePage) {
      return "Purchase";
    }
    return isSmallScreen ? "Download" : "Download Density";
  };

  const floatingBarHeight = () => {
    const floatingBarDiv = document.getElementById("om-vvvbnofp7uvf99fezrj4");
    return Math.max((floatingBarDiv?.clientHeight || 0) - 1, 0);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        top: `${floatingBarHeight()}px`,
        backgroundColor: isToggled
          ? "rgba(12, 12, 19, 0.74)"
          : visible
            ? "rgba(12, 12, 19, 0.1)"
            : "transparent",
        backgroundImage: "none",
        boxShadow: "none",
      }}
    >
      <>
        {ww < 769 && (
          <Box
            sx={{
              width: "100%",
              position: "fixed",
              top: 0,
              height: "68px",
              boxShadow: visible ? "0 4px 30px rgba(0, 0, 0, 0.1)" : "none",
              backdropFilter: visible ? "blur(30px)" : "none",
              "-webkit-backdrop-filter": visible ? "blur(30px)" : "none",
            }}
          ></Box>
        )}
        <Box
          sx={{
            backgroundColor: {
              xs: visible ? "rgba(12, 12, 19, 0.74)" : "transparent",
              md: visible ? "rgba(12, 12, 19, 0.1)" : "transparent",
            },
            backgroundImage: "none",
            boxShadow: visible ? "0 4px 30px rgba(0, 0, 0, 0.1)" : "none",
            backdropFilter: { md: visible ? "blur(30px)" : "none" },
            "-webkit-backdrop-filter": visible ? "blur(30px)" : "none",
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            padding: "0 88px",
            "@media (max-width: 768px)": {
              padding: "0",
            },
          }}
        >
          <Container
            maxWidth={"lg"}
            sx={{
              padding: {
                xs: "0 16px",
                md: "0",
              },
            }}
          >
            <Toolbar
              sx={{
                width: "100%",
                justifyContent: { xs: "space-between" },
                minHeight: "68px",
              }}
              disableGutters
            >
              {isOnboarding ? (
                <img
                  src={LandingLogo}
                  alt="logo"
                  style={{
                    width: "102px",
                    height: "32px",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <a style={{ display: "flex", alignItems: "center" }} href="/">
                  <img
                    src={LandingLogo}
                    alt="logo"
                    style={{
                      width: "102px",
                      height: "32px",
                      objectFit: "contain",
                    }}
                  />
                </a>
              )}

              <Box sx={{ display: { xs: "flex" }, marginLeft: { md: "auto" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={toggleNavbar}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  display: { xs: "none", md: "flex" },
                }}
              >
                {pages.map((page) => (
                  <Button
                    key={page.name}
                    onClick={page.handleOnClick}
                    sx={{
                      my: 2,
                      color: "white",
                      display: "block",
                      fontFamily: "IBM Plex Mono",
                      fontWeight: 600,
                      fontSize: "13px",
                      lineHeight: "16px",
                      letterSpacing: "1.25px",
                      textTransform: "uppercase",
                      "&:hover": { color: "#c1c5dd", backgroundColor: "unset" },
                      paddingLeft: {
                        xs: "8px",
                        md: "44px",
                      },
                      paddingRight: {
                        xs: "8px",
                        md: "52px",
                      },
                    }}
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>
              <StyledAuthButton
                variant="contained"
                color="primary"
                onClick={getDensity}
                sx={{
                  width: {
                    xs: 95,
                    md: 150,
                  },
                  height: {
                    xs: 28,
                    md: 36,
                  },
                  backgroundColor: "#c1c5dd",
                  margin: 0,
                  padding: "6px",
                  fontSize: {
                    xs: "10px",
                    md: "11px",
                  },
                  lineHeight: "16px",
                  letterSpacing: "1.25px",
                  fontWeight: 600,
                  display: { xs: visible ? "block" : "none" },
                }}
              >
                {getButtonText()}
              </StyledAuthButton>
            </Toolbar>
          </Container>
        </Box>
        <AnimatePresence>
          {isToggled && (
            <motion.div
              className="navbar"
              initial="hidden"
              animate={isToggled ? "visible" : "hidden"}
              exit="hidden"
              variants={navContainer}
            >
              <NavbarItems closeNavbar={closeNavbar} />
            </motion.div>
          )}
        </AnimatePresence>
      </>
      <LoginModal />
      <ForgotPasswordModal />
    </AppBar>
  );
};
